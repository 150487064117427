import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {BehaviorSubject, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {JwtHelperService} from "@auth0/angular-jwt";
import {environment} from "../../../environments/environment";

import {User} from "./models/user";
import {DeviceInfo} from "./models/device-info";
import {UUIDGenerator} from "../utils/uuid-generator";
import {Tokens} from "./models/tokens";
import {LoginCommand} from "./models/login-command";

@Injectable()
export class AuthService {
  user = new BehaviorSubject(null);

  private _url = environment.url;
  private static _jwtHelper = new JwtHelperService();

  constructor(private _http: HttpClient, private _router: Router) {
  }

  static getUser() {
    const accessToken = AuthService.getAccessToken();
    if (!accessToken) {
      return null;
    }
    const user = new User();
    const decodedToken = this._jwtHelper.decodeToken(accessToken);
    user.role = decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
    user.login = decodedToken['unique_name'];
    user.id = decodedToken['sub'];
    return user;
  }

  static isAuthenticated(): boolean {
    return !!AuthService.getAccessToken();
  }

  static getDeviceInfo(): DeviceInfo {
    return JSON.parse(localStorage.getItem('deviceInfo'));
  }

  private static setDeviceInfo() {
    const deviceInfo = new DeviceInfo();
    deviceInfo.deviceId = UUIDGenerator.GetNewUUID();
    deviceInfo.deviceName = window.navigator.userAgent;
    localStorage.setItem('deviceInfo', JSON.stringify(deviceInfo));
  }

  static getAccessToken(): string {
    return JSON.parse(localStorage.getItem('access-token'));
  }

  static setAccessToken(token: string) {
    localStorage.setItem('access-token', JSON.stringify(token));
  }

  static getRefreshToken(): string {
    return JSON.parse(localStorage.getItem('refresh-token'));
  }

  static setRefreshToken(token: string) {
    localStorage.setItem('refresh-token', JSON.stringify(token));
  }

  login(username: string, password: string) {
    if (!AuthService.getDeviceInfo()) {
      AuthService.setDeviceInfo();
    }

    const {deviceId, deviceName} = AuthService.getDeviceInfo();

    const loginCommand =
      new LoginCommand(username, password, deviceId, deviceName);

    return this._http.post<Tokens>(`${this._url}/tokens`, loginCommand)
      .pipe(
        map((tokens: Tokens) => {
          AuthService.setAccessToken(tokens.accessToken);
          AuthService.setRefreshToken(tokens.refreshToken);
          console.log(AuthService.getUser());
          return true;
        }),
        catchError(() => of(false))
      );
  }

  resetPassword(newPassword: string, resetCode: string) {
    return this._http.put(`${this._url}/me/password/reset`, {newPassword, resetCode});
  }

  logout() {
    localStorage.removeItem('access-token');
    localStorage.removeItem('refresh-token');
    localStorage.removeItem('user');
    this.user.next(AuthService.getUser());
    this._router.navigate(['login']);
  }

  logoutWithoutRedirecting() {
    localStorage.removeItem('access-token');
    localStorage.removeItem('refresh-token');
    localStorage.removeItem('user');
    this.user.next(AuthService.getUser());
  }

  refreshToken() {
    const refreshTokenCommand = {
      refreshToken: AuthService.getRefreshToken()
    };
    return this._http.post<Tokens>(`${this._url}/tokens/refresh`, refreshTokenCommand)
      .pipe(
        map(tokens => {
          AuthService.setAccessToken(tokens.accessToken);
          AuthService.setRefreshToken(tokens.refreshToken);
          return tokens;
        })
      );
  }
}
