import {Component, OnInit} from '@angular/core';
import {FormGroup, FormBuilder, Validators, AbstractControl} from '@angular/forms';
import {FormValidators} from '../../../../shared/form-validators';
import {AuthService} from '../../auth.service';
import {ActivatedRoute} from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: [
    './password-reset.component.scss',
    '../shared/non-login-view.scss'
  ]
})
export class PasswordResetComponent implements OnInit{
  passwordResetForm: FormGroup;
  hideForm = false;
  resetCode: string;
  readonly inputsMaxLength = 255;
  readonly inputsMinLength = 4;

  get newPassword(): AbstractControl {
    return this.passwordResetForm.get('newPassword');
  }

  get newPasswordError(): string {
    if (this.newPassword.errors.required)
      return 'Nowe hasło jest wymagane';

    if (this.newPassword.errors.minlength)
      return `Nowe hasło musi mieć co najmniej ${this.inputsMinLength} znaków`;

    if (this.newPassword.errors.maxlength)
      return `Nowe hasło może mieć maksymalnie ${this.inputsMaxLength} znaków`;

    return '';
  }

  get confirmPassword(): AbstractControl {
    return this.passwordResetForm.get('confirmPassword');
  }

  get confirmPasswordError(): string {
    if (this.confirmPassword.errors.required)
      return 'Powtórz nowe hasło';

    return '';
  }

  get formError(): string {
    if (this.passwordResetForm.hasError('notEquivalent'))
      return `Powtórzone hasło nie pasuje`;

    return '';
  }

  constructor(
    public snackBar: MatSnackBar,
    private _fb: FormBuilder,
    private _authService: AuthService,
    private _route: ActivatedRoute
  ) {
    this.initializeResetPasswordForm();
  }

  ngOnInit() {
    this._route.paramMap
      .subscribe(params => {
        this.resetCode = params.get('code');
      });
  }

  resetPassword() {
    this._authService.resetPassword(this.newPassword.value, this.resetCode)
      .subscribe(() => {
        this.hideForm = true;
      }, errorResponse => {
        if (errorResponse.status === 400) {
          this.snackBar.open('Link wygasł lub jest niepoprawny');
          this.hideForm = false;
        }
      });
  }

  private initializeResetPasswordForm() {
    this.passwordResetForm = this._fb.group({
      newPassword: [
        '', [
          Validators.required,
          Validators.maxLength(this.inputsMaxLength),
          Validators.minLength(this.inputsMinLength)
        ]
      ],
      confirmPassword: [
        '', [
          Validators.required
        ]
      ]
    }, {validator: FormValidators.matchingPassword('newPassword', 'confirmPassword')});
  }
}
