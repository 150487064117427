import {LOCALE_ID, NgModule} from '@angular/core';
import {registerLocaleData} from '@angular/common';
import localePl from '@angular/common/locales/pl';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';

import {ConfirmDeleteDialogComponent} from './components/confirm-delete-dialog/confirm-delete-dialog.component';
import {SharedModule} from '../shared/shared.module';
import {AuthService} from './auth/auth.service';
import {AuthGuard} from './auth/auth.guard';
import {TokenInterceptor} from './auth/token-interceptor.service';
import {LoginComponent} from './auth/components/login/login.component';
import {PasswordResetComponent} from './auth/components/password-reset/password-reset.component';
import {ImageService} from './services/image.service';
import {environment} from '../../environments/environment';
import {API_BASE_URL, IMAGE_URL} from '../shared/injection-tokens';

registerLocaleData(localePl, 'pl');

@NgModule({
  declarations: [
    LoginComponent,
    ConfirmDeleteDialogComponent,
    PasswordResetComponent
  ],
  entryComponents: [
    ConfirmDeleteDialogComponent
  ],
  imports: [
    SharedModule,
    HttpClientModule
  ],
  exports: [
    LoginComponent,
    ConfirmDeleteDialogComponent,
  ],
  providers: [
    AuthService,
    AuthGuard,
    {provide: LOCALE_ID, useValue: 'pl'},
    {provide: API_BASE_URL, useValue: environment.url},
    {provide: IMAGE_URL, useValue: environment.imageUrl},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    ImageService
  ]
})
export class CoreModule {}
