import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { AuthService } from "./auth.service";
import { Observable } from "rxjs";

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private _router: Router, private _authService: AuthService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    if (AuthService.getRefreshToken() && AuthService.getAccessToken()) {
      return true;
    }
    this._authService.logoutWithoutRedirecting();
    this._router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
    return false;
  }
}
