import {Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {ImageService} from '../../../core/services/image.service';
import {Subscription} from 'rxjs';
import {IMAGE_URL} from '../../injection-tokens';
import {ImageResource} from '../../models/image-resource';
import {FileValidator} from 'ngx-material-file-input';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-upload-image-input',
  templateUrl: './upload-image-input.component.html',
  styleUrls: ['./upload-image-input.component.scss']
})
export class UploadImageInputComponent implements OnInit, OnDestroy {
  readonly imageMaxSize = 5242880;
  image = new FormControl(null, [FileValidator.maxContentSize(this.imageMaxSize)]);
  imageSrc = null;
  uploadingImageInProgress = false;
  private imageValueChangesSub: Subscription;

  @Output() imageIdChanges = new EventEmitter();
  @Output() imageChanges = new EventEmitter();

  @Input()
  set imageResource(image: ImageResource) {
    if (image) {
      this.setImageSrc(image);
    }
  }

  constructor(
    @Inject(IMAGE_URL) public imageUrl: string,
    private imageService: ImageService,
    private snackBar: MatSnackBar) {}

  ngOnInit(): void {
    this.imageValueChangesSub = this.image.valueChanges.subscribe(input => {
      if (input && input.files.length > 0 && input.files[0].size < this.imageMaxSize) {
        const file = input.files[0];

        this.uploadingImageInProgress = true;
        this.imageService.uploadImage(file)
          .subscribe(res => {
            this.setImageSrc(res);
            this.imageIdChanges.next(res.id);
            this.imageChanges.next(res);
            this.uploadingImageInProgress = false;
          });
      }
    },
    err => {
      this.image.setValue(null);
      this.snackBar.open('Nie udało się załadować zdjcia.');
    });
  }

  get imageErrors(): string {
    if (this.image.errors.maxContentSize) {
      return `Rozmiar zdjęcia nie może być większy niż ${this.imageMaxSize / 1048576} MB`;
    }

    return '';
  }

  ngOnDestroy(): void {
    this.imageValueChangesSub.unsubscribe();
  }

  private setImageSrc(image: ImageResource) {
    this.imageSrc = `${this.imageUrl}${image.thumbnailPath}`;
  }
}
