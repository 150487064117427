import { FormControl, FormGroup, AbstractControl, ValidatorFn, FormArray } from '@angular/forms';

export class FormValidators {
  static matchingPassword(password: string, repeatedPassword: string) {
    return (formGroup: FormGroup) => {
      const pas1 = formGroup.get(password).value;
      const pas2 = formGroup.get(repeatedPassword).value;
      if (pas1 !== pas2) {
        return { notEquivalent: true };
      }
      return null;
    };
  }

  static mustBeANumber(control: FormControl) {
    const isNumber = (control.value || '');
    if (isNumber > 2147483647) {
      return { tooBig: true };
    }

    if (isNumber.toString().match(/^[0-9]+?$/)) {
      return null;
    }
    return { notNumber: true };
  }

  static noWhitespace(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  static unique(values: Array<any>): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (values.includes(control.value)) {
        return { unique: true };
      }

      return null;
    };
  }

  static uniqueInArray(controlName: string): ValidatorFn {
    return (formArray: FormArray): { [key: string]: any } | null => {
      const values = formArray.controls.map(c => c.get(controlName).value);
      const formArrayLength = formArray.length;

      for (let i = 0; i < formArrayLength; i++) {
        const controlValue = formArray.controls[i].get(controlName).value;

        if (values.filter(v => v === controlValue).length > 1) {
          return { uniqueInArray: { controlName, value: controlValue } };
        }
      }

      return null;
    };
  }
}
