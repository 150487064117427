import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';

import {LoadingIndicatorComponent} from './components/loading-indicator/loading-indicator.component';
import {AngularMaterialImportModule} from '../angular-material-import.module';
import {GoBackButtonComponent} from './components/go-back-button/go-back-button.component';
import {NotFoundComponent} from './components/not-found/not-found.component';
import {UploadImageInputComponent} from './components/upload-image-input/upload-image-input.component';
import {TableImagePreviewComponent} from './components/table-image-preview/table-image-preview.component';

@NgModule({
  declarations: [
    LoadingIndicatorComponent,
    GoBackButtonComponent,
    NotFoundComponent,
    UploadImageInputComponent,
    TableImagePreviewComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AngularMaterialImportModule,
    RouterModule
  ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AngularMaterialImportModule,
        LoadingIndicatorComponent,
        GoBackButtonComponent,
        NotFoundComponent,
        UploadImageInputComponent,
        TableImagePreviewComponent
    ]
})
export class SharedModule {}
