import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ImageResource} from '../../shared/models/image-resource';
import {API_BASE_URL} from '../../shared/injection-tokens';

@Injectable({
  providedIn: 'root'
})
export class ImageService {

  constructor(
    private http: HttpClient,
    @Inject(API_BASE_URL) private apiBaseUrl: string) {}

  uploadImage(file: any): Observable<ImageResource> {
    const formData = new FormData();
    formData.append('image', file);

    return this.http.post<ImageResource>(`${this.apiBaseUrl}/images`, formData);
  }
}
