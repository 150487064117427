import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {LoginComponent} from './core/auth/components/login/login.component';
import {AuthGuard} from './core/auth/auth.guard';
import {PasswordResetComponent} from './core/auth/components/password-reset/password-reset.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'employees'
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'reset-password/:code',
    component: PasswordResetComponent
  },
  {
    path: 'employees',
    canActivate: [AuthGuard],
    loadChildren: () => import('./features/employees/employees.module').then(m => m.EmployeesModule)
  },
  {
    path: 'announcements',
    canActivate: [AuthGuard],
    loadChildren: () => import('./features/announcements/announcements.module').then(m => m.AnnouncementsModule)
  },
  {
    path: 'safety-notifications',
    canActivate: [AuthGuard],
    loadChildren: () => import('./features/safety-notifications/safety-notifications.module').then(m => m.SafetyNotificationsModule)
  },
  {
    path: 'hits-of-the-month',
    canActivate: [AuthGuard],
    loadChildren: () => import('./features/hits-of-the-month/hits-of-the-month.module').then(m => m.HitsOfTheMonthModule)
  },
  {
    path: 'accidents',
    canActivate: [AuthGuard],
    loadChildren: () => import('./features/accidents/accidents.module').then(m => m.AccidentsModule)
  },
  {
    path: 'news',
    canActivate: [AuthGuard],
    loadChildren: () => import('./features/news/news.module').then(m => m.NewsModule)
  },
  {
    path: 'calendars',
    canActivate: [AuthGuard],
    loadChildren: () => import('./features/calendars/calendars.module').then(m => m.CalendarsModule)
  },
  {
    path: '**',
    redirectTo: 'employees'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
