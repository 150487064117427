import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: [
    './login.component.scss',
    '../shared/non-login-view.scss'
  ]
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  isWaitingForResponse = false;
  readonly inputsMaxLength = 255;
  private _returnUrl: string;

  get username(): AbstractControl {
    return this.loginForm.get('username');
  }

  get usernameError(): string {
    if (this.username.errors.required)
      return 'Login jest wymagany';

    if (this.username.errors.maxlength)
      return `Login może mieć maksymalnie ${this.inputsMaxLength} znaków`;

    return '';
  }

  get password(): AbstractControl {
    return this.loginForm.get('password');
  }

  get passwordError(): string {
    if (this.password.errors.required)
      return 'Hasło jest wymagane';

    if (this.password.errors.maxlength)
      return `Hasło może mieć maksymalnie ${this.inputsMaxLength} znaków`;

    return '';
  }

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _fb: FormBuilder,
    private _authService: AuthService
  ) {
    this.initializeLoginForm();
  }

  ngOnInit() {
    if (AuthService.isAuthenticated()) {
      this._router.navigateByUrl('');
    }
    this._returnUrl = this._route.snapshot.queryParams['returnUrl'] || 'employees';
  }

  login() {
    this.isWaitingForResponse = true;
    this._authService.login(this.username.value, this.password.value)
      .subscribe(result => {
        if (result) {
          this._router.navigate([this._returnUrl]);
        } else {
          this.loginForm.setErrors({
            invalidCredentials: true
          });
        }
        this.isWaitingForResponse = false;
      });
  }

  private initializeLoginForm() {
    this.loginForm = this._fb.group({
      username: [
        '',
        [
          Validators.required,
          Validators.maxLength(this.inputsMaxLength)
        ]
      ],
      password: [
        '',
        [
          Validators.required,
          Validators.maxLength(this.inputsMaxLength)
        ]
      ]
    });
  }
}
