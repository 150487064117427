import {Injectable} from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpSentEvent,
  HttpProgressEvent, HttpHeaderResponse, HttpResponse, HttpUserEvent, HttpErrorResponse
} from "@angular/common/http";
import {BehaviorSubject, EMPTY, Observable, throwError} from "rxjs";
import {catchError, filter, finalize, switchMap, take} from "rxjs/operators";

import {AuthService} from "./auth.service";
import {Tokens} from "./models/tokens";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) {}

  isRefreshingToken: boolean = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  logInfo = true;

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any> | any> {
    this.log(`Intercepting: ${request.url}`);
    return next.handle(TokenInterceptor.addTokenToRequest(request, AuthService.getAccessToken()))
      .pipe(
        catchError(err => {
          this.log(err);
          if (err instanceof HttpErrorResponse && (<HttpErrorResponse>err).status == 401) {
            this.log('Handling 401');
            return this.handle401Error(request, next);
          } else {
            this.log('Rethrowing ex');
            return throwError(err);
          }
        }));
  }

  private static addTokenToRequest(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return !token ? request : request.clone({setHeaders: {Authorization: `Bearer ${token}`}});
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshingToken) {
      this.log('Set isRefreshing to true');
      this.isRefreshingToken = true;

      this.tokenSubject.next(null);

      this.log('Refreshing token');
      return this.authService.refreshToken()
        .pipe(
          switchMap((tokens: Tokens) => {
            if (tokens) {
              this.log('Token refreshed');
              this.log(`Retry request: ${request.url}`);
              this.tokenSubject.next(tokens.accessToken);
              return next.handle(TokenInterceptor.addTokenToRequest(request, tokens.accessToken));
            }

            this.log('Error no token returned, logout');
            this.authService.logout();
            return EMPTY;
          }),
          catchError(err => {
            this.log("Refresh failed, logout");
            this.authService.logout();
            return EMPTY;
          }),
          finalize(() => {
            this.log('Set isRefreshing to false');
            this.isRefreshingToken = false;
          })
        );
    } else {
      this.log('Waiting for refresh');
      return this.tokenSubject
        .pipe(
          filter(token => token != null),
          take(1),
          switchMap(token => {
            this.log(`Retry request: ${request.url}`);
            return next.handle(TokenInterceptor.addTokenToRequest(request, token));
          }));
    }
  }

  private log(info: any) {
    this.logInfo && console.log(info);
  }
}
