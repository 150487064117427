import {Component, Input} from '@angular/core';

@Component({
  selector: 'go-back-button',
  templateUrl: './go-back-button.component.html',
  styleUrls: ['./go-back-button.component.scss']
})
export class GoBackButtonComponent {
  @Input() destinationName: string;
  @Input() url: string;
}
