import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-loading-indicator',
  template: `
    <div *ngIf="visible" class="mat-elevation-z8 spinner-wrapper">
      <mat-spinner></mat-spinner>
    </div>
  `,
  styles: [`
    .spinner-wrapper {
      min-height: 50vh;
      display: flex;
      align-items: center;
      justify-content: center;
      align-self: center;
    }
  `]
})
export class LoadingIndicatorComponent {
  @Input() visible: boolean;
}

