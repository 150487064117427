import { Component, Input } from '@angular/core';

@Component({
  selector: 'not-found',
  template: `
    <div class="not-found-message">
      {{message}}
    </div>
  `,
  styles: [`
    .not-found-message {
      padding: 25px;
      text-align: center;
      font-size: 20px;
    }
  `]
})
export class NotFoundComponent{
  @Input() message;
}
