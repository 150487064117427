import { Component } from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {AuthService} from "../auth/auth.service";

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent {

  isHandset$: Observable<boolean> = this._breakpointObserver.observe([Breakpoints.Medium, Breakpoints.Handset])
    .pipe(
      map(result => result.matches)
    );

  constructor(
    private _authService: AuthService,
    private _breakpointObserver: BreakpointObserver) {}

  logout() {
    this._authService.logout();
  }
}
