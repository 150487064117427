import {ChangeDetectionStrategy, Component, Inject, Input} from '@angular/core';
import {ImageResource} from '../../models/image-resource';
import {IMAGE_URL} from '../../injection-tokens';

@Component({
  selector: 'app-table-image-preview',
  templateUrl: './table-image-preview.component.html',
  styleUrls: ['./table-image-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableImagePreviewComponent {
  @Input() image: ImageResource;
  constructor(@Inject(IMAGE_URL) public imageUrl: string) { }

  getImageThumbnailUrl(image: ImageResource) {
    return `${this.imageUrl}${image.thumbnailPath}`;
  }

  getImageUrl(image: ImageResource) {
    return `${this.imageUrl}${image.sourcePath}`;
  }
}
